import { Preferences } from '@capacitor/preferences'
import { useEffect, useState } from 'react'

type PreferencesType = {
  'last-in-app-review': string
  'payment-in-app-review': string
  'help-in-app-review': string
  'payment-csat': string
}

const DEFAULT_PREFERENCES: PreferencesType = {
  'last-in-app-review': '',
  'payment-in-app-review': '',
  'help-in-app-review': '',
  'payment-csat': '',
}

const usePreferences = () => {
  const [preferences, setPreferences] =
    useState<PreferencesType>(DEFAULT_PREFERENCES)

  useEffect(() => {
    const loadPreferences = async () => {
      const { keys } = await Preferences.keys()

      keys.forEach(async (key) => {
        const { value } = await Preferences.get({ key })

        setPreferences((prev: PreferencesType) => ({
          ...prev,
          [key]: value,
        }))
      })
    }

    loadPreferences()
  }, [])

  const set = async (name: keyof PreferencesType, value: any) => {
    await Preferences.set({ key: name, value })

    setPreferences((prev: any) => ({
      ...prev,
      [name]: value,
    }))
  }

  const remove = async (name: string) => {
    await Preferences.remove({ key: name })

    setPreferences((prev: any) => {
      const { [name]: _, ...rest } = prev

      return rest
    })
  }

  return {
    preferences,
    set,
    remove,
  }
}

export default usePreferences
